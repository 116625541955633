import axios from 'axios'
import { environment } from '@/data/environment/'

export default {
  namespaced: true,
  state: {
    statusResponse: false,
    statusResponseLogTokenFolios: {error:false,Mensaje:""},
    statusResponseFoliosFromHKA: {error:false},
    foliosData: [],
    listClients: []
  },
  mutations: {
    setListClients (state, data) {
      state.listClients = data
    },
    setFoliosData(state, data) {
      state.foliosData = data
    },
    setResponseFolios(state, data) {
      state.statusResponse = data
    },
    setResponseLogTokenFolios(state, data) {
      state.statusResponseLogTokenFolios = data
    },
    setResponseFoliosFromHKA(state, data) {
      state.statusResponseFoliosFromHKA = data
    },
  },
  actions: {
    getListClients ({ commit }) {
      const endpoint = '/api/getClients'
      const url = environment.HIOPOSWEBSERVICES + endpoint
      axios
        .create()
        .get(url)
        .then(response => {
          commit('setListClients', response.data)
        })
        .catch(function (error) {
          console.log(error.message)
        })
    },
    getFoliosData({commit }, data) {
      let params = 'IdCompania=' + data.IdCompania

      const endpoint = '/api/getFoliosData?'
      const url = environment.HIOPOSWEBSERVICES + endpoint + params

      axios
        .create()
        .get(url)
        .then(response => {
          commit('setFoliosData', response.data)
        })
        .catch(function (error) {
          console.log(error.message)
        })
    },
    updateFoliosData({ commit }, data) {
      const endpoint = '/api/updateFoliosData'
      const url = environment.HIOPOSWEBSERVICES + endpoint

      data.Usuario = JSON.parse(atob(localStorage.getItem('uid'))).user

      axios
        .create()
        .put(url, data)
        .then(response => {
          commit('setResponseFolios', response.data.error === false)
        })
        .catch(function (error) {
          console.log(error.message)
        })
    },
    insertLogTokenFolios({ commit }, data) {
      const endpoint = '/api/insertLogTokenFolios'
      const url = environment.HIOPOSWEBSERVICES + endpoint

      axios
        .create()
        .post(url, data)
        .then(response => {
          commit('setResponseLogTokenFolios', response.data)
        })
        .catch(function (error) {
          console.log(error.message)
        })
    },
    getFoliosDataFromHKA({commit }, data) {
      let params = 'IdCompania=' + data.idCompania

      const endpoint = '/api/getFoliosDataFromHKA?'
      const url = environment.HIOPOSWEBSERVICES + endpoint + params

      axios
        .create()
        .get(url)
        .then(response => {
          commit('setResponseFoliosFromHKA', response.data)
        })
        .catch(function (error) {
          console.log(error.message)
        })
    },
    setStatusResponse({ commit }, data) {
      commit('setResponseFolios', data)
    },
  },
}
